<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('contactUsTopics.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/contact-us'"
        :reloadData="reloadData"
        :params="filter"
        :createBtnLabel="$t('contactUsTopics.create')"
        createRouteName="generalSettings.contactUsTopicCreate"
        :excelFields="excelFields"
        excelName="contactUsTopics"
        :downloadSampleStatus="true"
        downloadSampleUrl="contact-us-topics/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="contact-us-topics/excel"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import ContactUsTopicFilter from "../models/ContactUsTopicFilter";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      filter: new ContactUsTopicFilter(),
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "user_id", label: "user id", sortable: true },
        { key: "subject", label: "subject", sortable: true },
        { key: "message_body", label: "message body", sortable: true },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "generalSettings.contactUsTopicView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.update"),
              icon: "fas fa-pen",
              color: "primary",
              ifNavigate: true,
              routeName: "generalSettings.contactUsTopicUpdate",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.delete"),
              icon: "fas fa-trash-alt",
              color: "danger",
              showAlert: true,
              actionHeader: this.$t("global.delete"),
              titleHeader: this.$t("contactUsTopics.item"),
              textContent: "address",
              url: "admin/contact-us-topics",
              type: "delete",
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("table.name")]: "name",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },
  props: {
    cityId: { default: "" },
  },
  mounted() {
    core.index();
  },
  created() {
    this.filter.city_id = this.cityId;
  },
};
</script>
